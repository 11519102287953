import React, { useEffect, useState } from "react";
import { NftCommentData } from "../../models";
import * as ENDPOINT from "../../constants/endpoints";
import API from "../../api";
import Comment from "./Comment";

interface CommentListProps {
  nft_uuid: string;
}

const CommentList = (props: CommentListProps) => {
  const [comments, setComments] = useState<NftCommentData[] | undefined>();

  useEffect(() => {
    const getComments = async () => {
      try {
        const response = await API.get(ENDPOINT.NFT_COMMENTS, {
          params: { uuid: props.nft_uuid },
        });
        setComments(response.data);
      } catch {
        console.log("Error getting comments for NFT " + props.nft_uuid);
      }
    };
    getComments();
  }, [props.nft_uuid]);

  if (!comments) {
    return <React.Fragment />;
  }

  const renderedComments = comments.map(
    (comment: NftCommentData, index: number) => {
      return (
        <div className="p-1">
          <Comment {...comment} />
        </div>
      );
    }
  );

  return <div>{renderedComments}</div>;
};

export default CommentList;
