import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserInfo } from "../../models";
import API from "../../api";
import * as ENDPOINT from "../../constants/endpoints";
import React from "react";
import FollowButton from "../FollowButton";
import SessionContext from "../../session";
import ImportNftsButton from "./ImportNftsButton";

const UserInfoHeader = () => {
  const { username } = useParams();
  const { session } = useContext(SessionContext);
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>();
  const myProfile: boolean = username === session.user.username;

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const response = await API.get(ENDPOINT.USER_INFO, {
          params: { username: username },
        });
        setUserInfo(response.data);
      } catch {
        console.log("Error fetching user info");
      }
    };
    getUserInfo();
  }, [username]);

  if (!userInfo) {
    return <React.Fragment />;
  }

  return (
    <div className="container mx-auto max-w-screen-lg h-full">
      <div className="grid grid-flow-col gap-4 items-center">
        <div className="justify-self-center">
          <img
            className="h-32 w-32 rounded-full ring-2 ring-white"
            src={userInfo.picture}
            alt=""
          />
        </div>
        <div className="col-span-4 grid-flow-row">
          <div className="flex flow-row">
            <div>
              <h1 className="text-2xl font-semibold">{userInfo.username}</h1>
              <p className="mb-7 text-gray-800">{userInfo.fullname}</p>
            </div>
            {!myProfile && session.authenticated && (
              <div className="ml-4">
                <FollowButton
                  username={userInfo.username}
                  following={userInfo.observer_is_following}
                />
              </div>
            )}
          </div>
          <div className="grid grid-flow-col">
            <div className="flex flex-row">
              <p className="mr-1 font-bold">{userInfo.total_nfts}</p>
              <p>NFTs</p>
            </div>
            <div className="flex flex-row">
              <p className="mr-1 font-bold">{userInfo.followers}</p>
              <p>followers</p>
            </div>
            <div className="flex flex-row">
              <p className="mr-1 font-bold">{userInfo.following}</p>
              <p>following</p>
            </div>
            {myProfile && <ImportNftsButton />}
          </div>
        </div>
        <div className="col-span-4"></div>
      </div>
    </div>
  );
};

export default UserInfoHeader;
