import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../../api";
import * as ENDPOINT from "../../constants/endpoints";
import Suggestion from "./Suggestion";
import { UserInfoSlim } from "../../models";

const Suggestions = () => {
  const { username } = useParams();
  const [suggestions, setSuggestions] = useState<UserInfoSlim[] | undefined>();

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const response = await API.get(ENDPOINT.FOLLOW_SUGGESTIONS, {
          params: { username: username },
        });
        setSuggestions(response.data);
      } catch {
        console.log("Error fetching user info");
      }
    };
    getUserInfo();
  }, [username]);

  const renderedSuggestions = () => {
    if (!suggestions) {
      return <React.Fragment />;
    }
    return suggestions.map((user: UserInfoSlim, index: number) => {
      return <Suggestion {...user} key={index} />;
    });
  };

  return (
    <div>
      <h1 className="font-semibold mb-3">Suggestions for you</h1>
      {renderedSuggestions()}
    </div>
  );
};

export default Suggestions;
