import { useContext } from "react";
import { Navigate } from "react-router-dom";
import SessionContext from "../session";
import * as ROUTE from "../constants/routes";

const ProtectedRoute = ({ children }: any) => {
  const { session } = useContext(SessionContext);
  if (!session.authenticated) {
    console.log("Navigating out");
    return <Navigate to={ROUTE.LOGIN} replace={true} />;
  }
  return children;
};

export default ProtectedRoute;
