import React from "react";
import * as ENDPOINT from "./constants/endpoints";
import API from "./api";

const ANONYMOUS_ID = -1;

export interface UserInfo {
  id: number;
  username: string;
  fullname: string;
  email: string;
}

export interface Session {
  authenticated: boolean;
  user: UserInfo;
}

export const makeEmptySession = () => {
  const session: Session = {
    authenticated: false,
    user: {
      id: -1,
      username: "",
      fullname: "",
      email: "",
    },
  };
  return session;
};

export const getCurrentSession = async () => {
  let session: Session;
  try {
    const response = await API.get(ENDPOINT.WHOAMI);
    if (response.data.id !== ANONYMOUS_ID) {
      session = {
        authenticated: true,
        user: response.data,
      };
    } else {
      session = makeEmptySession();
    }
  } catch {
    session = makeEmptySession();
  }
  return session;
};

const SessionContext = React.createContext({
  session: makeEmptySession(),
  setSession: (value: any) => {},
});

export default SessionContext;
