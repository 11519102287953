import * as ENDPOINT from "../../constants/endpoints";
import API from "../../api";

import { useState } from "react";

interface CommentFormProps {
  nft_uuid: string;
  onSubmit: CallableFunction;
}

const CommentForm = (props: CommentFormProps) => {
  const [comment, setComment] = useState<string | undefined>();

  const postComment = async (event: any) => {
    event.preventDefault();
    try {
      await API.post(ENDPOINT.NFT_ADD_COMMENT, null, {
        params: {
          nft_uuid: props.nft_uuid,
          comment: comment,
        },
      });
      props.onSubmit();
    } catch {
      console.log("Error posting comment");
    } finally {
      setComment("");
    }
  };

  return (
    <div>
      <form onSubmit={postComment} method="POST">
        <div className="flex flex-row items-center">
          <div className="grow">
            <input
              autoComplete="off"
              type="comment"
              id="comment"
              className="border-none focus:ring-0 bg-white text-gray-500 focus:text-gray-800 text-sm block w-full p-2.5"
              placeholder="Add a comment..."
              onChange={({ target }) => setComment(target.value)}
              required
              value={comment}
            />
          </div>
          <div className=" ml-4 mr-4">
            <button
              disabled={!comment}
              type="submit"
              className="text-sm text-gray-500 font-semibold"
            >
              Post
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CommentForm;
