import React, { useEffect, useState } from "react";
import { NftAddedEventData, NftWithSocial } from "../../models";
import API from "../../api";
import * as ENDPOINT from "../../constants/endpoints";
import LinkToUser from "../profile/LinkToUser";
import Image from "../nft/Image";
import Footer from "../nft/Footer";
import Actions from "../nft/Actions";
import When from "./When";
import NftViewModal from "../nft/NftViewModal";

const NftAddedEvent = (props: NftAddedEventData) => {
  const [nftWithSocial, setNftWithSocial] = useState<
    NftWithSocial | undefined
  >();
  const [showNftViewModal, setShowNftViewModal] = useState<boolean>(false);

  useEffect(() => {
    const getNft = async () => {
      try {
        const response = await API.get(ENDPOINT.NFT_GET, {
          params: {
            uuid: props.nft_uuid,
          },
        });
        setNftWithSocial(response.data);
      } catch {
        console.log("Error getting NFT ", props.nft_uuid);
      }
    };
    getNft();
  }, [props.nft_uuid]);

  if (!nftWithSocial) {
    return <React.Fragment />;
  }

  const nft = nftWithSocial.nft;

  return (
    <div className="flex flex-col rounded border bg-white border-gray-200 m-6 max-w-md shadow-sm  ">
      <div className="flex flex-row items-center">
        <img
          className="m-1 h-12 w-12 rounded-full ring-2 ring-white"
          src=""
          alt=""
        />
        <div className="m-2 mr-4">
          <When className="text-sm text-gray-700" timestamp={props.timestamp} />
          <LinkToUser username={props.username} /> {" added a new NFT"}
        </div>
      </div>
      <div className="mx-auto">
        <button type="button" onClick={() => setShowNftViewModal(true)}>
          <Image
            src={nft.cached_media_url || nft.media_url}
            caption={nft.title}
            description={nft.description}
          />
        </button>
      </div>
      <Footer title={nft.title} description={nft.description} />
      <Actions
        nft_id={nft.id}
        totalLikes={nftWithSocial.total_likes}
        liked={nftWithSocial.observer_likes_it}
        handleFocus={() => {}}
      />
      <NftViewModal
        nftWithSocial={nftWithSocial}
        show={showNftViewModal}
        setShow={setShowNftViewModal}
      />
    </div>
  );
};

export default NftAddedEvent;
