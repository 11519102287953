import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./routes/Dashboard";
import Login from "./routes/Login";
import Signup from "./routes/Signup";
import "./index.css";
import * as ROUTE from "./constants/routes";
import SessionContext, { getCurrentSession, makeEmptySession } from "./session";
import { CookiesProvider } from "react-cookie";
import Settings from "./routes/Settings";
import Profile from "./routes/Profile";
import ProtectedRoute from "./routes/ProtectedRoute";

const App = () => {
  const [session, setSession] = useState(makeEmptySession());

  useEffect(() => {
    const getSession = async () => {
      const newSession = await getCurrentSession();
      setSession(newSession);
    };
    getSession();
  }, []);

  return (
    <React.StrictMode>
      <CookiesProvider>
        <SessionContext.Provider value={{ session, setSession }}>
          <BrowserRouter>
            <Routes>
              <Route path={ROUTE.PROFILE} element={<Profile />} />
              <Route path={ROUTE.LOGIN} element={<Login />} />
              <Route path={ROUTE.SIGNUP} element={<Signup />} />
              <Route
                path={ROUTE.DASHBOARD}
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.SETTINGS}
                element={
                  <ProtectedRoute>
                    <Settings />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </BrowserRouter>
        </SessionContext.Provider>
      </CookiesProvider>
    </React.StrictMode>
  );
};

export default App;
