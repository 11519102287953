interface ImageProps {
  src: string;
  caption: string;
  description?: string;
}

const Image = (props: ImageProps) => {
  return (
    <div className="overflow-hidden group relative aspect-square">
      <img
        className="group-hover:blur-sm group-hover:grayscale group-hover:brightness-50"
        src={props.src}
        alt={props.caption}
      />
      <div className="invisible group-hover:visible absolute top-0 left-0 px-6 py-4">
        <p className="text-sm leading-normal text-gray-100">
          {props.description}
        </p>
      </div>
    </div>
  );
};

export default Image;
