import debounce from "lodash.debounce";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { UserInfoSlim } from "../models";
import API from "../api";
import * as ENDPOINT from "../constants/endpoints";
import { Menu, Transition } from "@headlessui/react";
import { generatePath, Link } from "react-router-dom";
import * as ROUTE from "../constants/routes";

const SearchBar = () => {
  const [text, setText] = useState<string | undefined>();
  const [results, setResults] = useState<UserInfoSlim[] | undefined>();

  const doSearch = useCallback(async () => {
    if (!text) {
      setResults(undefined);
      return;
    }
    try {
      const response = await API.get(ENDPOINT.USER_SEARCH, {
        params: { text: text },
      });
      setResults(response.data);
    } catch {
      console.log("Error searching: ", text);
    }
  }, [text]);

  const debouncedDoSearch = useMemo(() => debounce(doSearch, 300), [doSearch]);

  useEffect(() => {
    debouncedDoSearch();
    return () => {
      // cleanup
      debouncedDoSearch.cancel();
    };
  }, [text, debouncedDoSearch]);

  const DropDown = () => {
    const renderedResults = results?.map(
      (user: UserInfoSlim, index: number) => {
        const linkToUser = generatePath(ROUTE.PROFILE, {
          username: user.username,
        });
        return (
          <div className="p-2 hover:bg-gray-100">
            <Menu.Item key={index}>
              <Link
                to={linkToUser}
                onClick={() => {
                  setResults(undefined);
                }}
              >
                <div className="flex flex-row items-center">
                  <div className="basis-16">
                    <img
                      className="h-12 w-12 rounded-full ring-2 ring-white"
                      src={user.picture}
                      alt=""
                    />
                  </div>
                  <div className="basis-32">
                    <h1 className="font-semibold">{user.username}</h1>
                    <p className="text-sm text-gray-600">{user.fullname}</p>
                  </div>
                </div>
              </Link>
            </Menu.Item>
          </div>
        );
      }
    );
    return (
      <Menu as="div" className="relative inline-block text-left">
        <Transition
          as={Fragment}
          show={results && results.length > 0}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            {renderedResults}
          </Menu.Items>
        </Transition>
      </Menu>
    );
  };

  return (
    <div>
      <div>
        <form>
          <input
            placeholder="Search..."
            aria-label="Search"
            type="text"
            id="search"
            name="search"
            autoComplete="off"
            className="text-sm text-gray-500 border rounded-lg border-gray-200"
            onChange={({ target }) => setText(target.value)}
            value={text}
          />
        </form>
      </div>
      {results && results.length > 0 ? <DropDown /> : <React.Fragment />}
    </div>
  );
};

export default SearchBar;
