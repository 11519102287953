export const LOGIN = "/auth/login";
export const SIGNUP = "/auth/signup";
export const WHOAMI = "/auth/whoami";
export const LOGOUT = "/auth/logout";
export const TOGGLE_LIKE = "/nft/toggle_like";
export const ACCOUNT_GET_ALL = "/account/get_all";
export const ACCOUNT_ADD = "/account/add";
export const ACCOUNT_DELETE = "/account/delete";
export const NFT_IMPORT_ALL = "/nft/import_all";
export const NFT_GET_ALL = "/nft/get_all";
export const NFT_GET = "/nft/get";
export const NFT_COMMENTS = "/nft/comments";
export const NFT_ADD_COMMENT = "/nft/add_comment";
export const NFT_SET_HIDDEN = "/nft/set_hidden";
export const USER_INFO = "/user/info";
export const TIMELINE = "/timeline";
export const FOLLOW_SUGGESTIONS = "/follow/suggestions";
export const FOLLOW_TOGGLE = "/follow/toggle_follow";
export const USER_SEARCH = "/user/search";
