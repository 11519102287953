interface FooterProps {
  title: string;
  description: string;
}

const Footer = (props: FooterProps) => {
  return (
    <div className="p-4 pt-2 pb-1">
      <span className="text-sm mr-1 font-bold">{props.title}</span>
    </div>
  );
};

export default Footer;
