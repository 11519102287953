import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SessionContext from "../session";
import Header from "../components/Header";
import API from "../api";
import * as ENDPOINT from "../constants/endpoints";
import NftContainer from "../components/nft/NftContainer";
import React from "react";
import UserInfoHeader from "../components/profile/UserInfoHeader";
import { NftWithSocial } from "../models";

const Profile = () => {
  const { username } = useParams();
  const { session } = useContext(SessionContext);
  const navigate = useNavigate();
  const [nfts, setNfts] = useState<NftWithSocial[]>();
  const myProfile: boolean = username === session.user.username;

  useEffect(() => {
    const getAllNfts = async () => {
      try {
        const response = await API.get(ENDPOINT.NFT_GET_ALL, {
          params: { username: username },
        });
        setNfts(response.data);
      } catch {
        console.log("Error fetching NFTs");
      }
    };
    getAllNfts();
  }, [navigate, session, username]);

  const RenderedNfts = () => {
    if (nfts) {
      return (
        <div className="container mx-auto grid grid-cols-3">
          {nfts.map((nft: NftWithSocial, index: number) => {
            // Mutating function to be passed to the children
            const setNft = (value: NftWithSocial) => {
              let _nfts = [...nfts];
              _nfts[index] = value;
              setNfts(_nfts);
            };
            if (!myProfile && nft.nft.hidden) return <React.Fragment />;
            return (
              <NftContainer
                key={nft.nft.id}
                nftWithSocial={nft}
                setNftWithSocial={setNft}
                myNft={myProfile}
              />
            );
          })}
        </div>
      );
    } else {
      return <React.Fragment />;
    }
  };

  return (
    <div className="bg-gray-100">
      <Header />
      <div className="mb-5">
        <UserInfoHeader />
      </div>
      <RenderedNfts />
    </div>
  );
};

export default Profile;
