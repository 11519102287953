import { AccountType } from "./Account";
import Account from "./Account";
import { LinkIcon } from "@heroicons/react/outline";
import ConnectWalletModal from "./ConnectWalletModal";
import { useEffect, useState } from "react";
import API from "../../api";
import * as ENDPOINT from "../../constants/endpoints";
import React from "react";

interface AccountsProps {
  accounts: AccountType[] | undefined;
  onChange: CallableFunction;
}

const Accounts = (props: AccountsProps) => {
  const [openModal, setOpenModal] = useState<string | undefined>();
  const [account, setAccount] = useState<AccountType>();

  useEffect(() => {
    const addAccount = async () => {
      if (account && account.chain && account.address) {
        try {
          await API.post(ENDPOINT.ACCOUNT_ADD, null, {
            params: { chain: account.chain, address: account.address },
          });
          setAccount(undefined);
          props.onChange();
        } catch {}
      }
    };
    addAccount();
  }, [account, props]);

  const handleDelete = async (account: AccountType) => {
    if (account && account.chain && account.address) {
      try {
        await API.delete(ENDPOINT.ACCOUNT_DELETE, {
          params: { chain: account.chain, address: account.address },
        });
        props.onChange();
      } catch {}
    }
  };

  return (
    <div className="grid grid-rows-2">
      <div className="flex items-center justify-right">
        <button
          type="button"
          onClick={() => setOpenModal("default")}
          className="bg-transparent text-gray-700 hover:text-gray-900 hover:bg-gray-50 font-medium py-1 px-3 border inline-flex items-center border-gray-200 hover:border-gray-400 rounded"
        >
          <LinkIcon className="mr-2 w-4 h-4" />
          Connect Wallet
        </button>
      </div>
      <ConnectWalletModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleWallet={setAccount}
      />
      {props.accounts && props.accounts.length > 0 ? (
        <div className="box w-min border shadow-lg mt-3">
          {props.accounts.map((account: AccountType, index: number) => {
            return (
              <React.Fragment key={index}>
                <Account
                  account={account}
                  onDelete={() => {
                    handleDelete(account);
                  }}
                />
              </React.Fragment>
            );
          })}
        </div>
      ) : (
        <h3 className="p-2">No accounts</h3>
      )}
    </div>
  );
};

export default Accounts;
