import { useState } from "react";
import * as ENDPOINT from "../constants/endpoints";
import API from "../api";

interface FollowButtonProps {
  username: string;
  following: boolean;
}

const FollowButton = (props: FollowButtonProps) => {
  const [following, setFollowing] = useState<boolean>(props.following);

  const onFollowClick = async () => {
    try {
      const response = await API.post(ENDPOINT.FOLLOW_TOGGLE, null, {
        params: { username: props.username },
      });
      setFollowing(response.data);
    } catch {
      console.log("Error fetching user info");
    }
  };
  return (
    <button
      type="button"
      onClick={onFollowClick}
      className="rounded border border-gray-200 bg-transparent py-1 px-3 font-medium text-sm text-gray-700 hover:border-gray-400 hover:bg-gray-50 hover:text-gray-900"
    >
      {!following ? "Follow" : "Following"}
    </button>
  );
};

export default FollowButton;
