import { useState } from "react";
import { NftWithSocial } from "../../models";
import { Modal } from "../modal/Modal";
import ActionsModal from "./ActionsModal";
import CommentForm from "./CommentForm";
import CommentList from "./CommentList";

interface NftViewModalProps {
  show: boolean;
  setShow: CallableFunction;
  nftWithSocial: NftWithSocial;
}

const NftViewModal = (props: NftViewModalProps) => {
  const nft = props.nftWithSocial.nft;
  const [uuid, setUuid] = useState<string>(nft.uuid);

  return (
    <Modal
      show={props.show}
      onClose={() => props.setShow(false)}
      size="4xl"
      bg="bg-gray-100"
    >
      <Modal.Header></Modal.Header>
      <Modal.Body className="space-y-2">
        {props.show && (
          <div className="grid grid-cols-2">
            <img
              className="object-cover h-96 w-96 group-hover:blur-sm group-hover:grayscale group-hover:brightness-50"
              src={nft.cached_media_url || nft.media_url}
              alt=""
            />
            <div className="flex flex-col divide-y">
              <div className="flex-none h-10">
                <h2 className="text-sm font-semibold text-gray-900 lg:text-l">
                  {nft.title}
                </h2>
              </div>
              <div className="grow overflow-auto">
                <CommentList nft_uuid={nft.uuid} />
              </div>
              <div className="flex-none h-18 mt-2">
                <div className="flex flex-col">
                  <ActionsModal
                    nft_id={nft.id}
                    totalLikes={props.nftWithSocial.total_likes}
                    liked={props.nftWithSocial.observer_likes_it}
                    handleFocus={() => {}}
                  />
                  <CommentForm
                    nft_uuid={uuid}
                    onSubmit={() => {
                      let u = uuid;
                      setUuid("");
                      setUuid(u); // We use this trick to refresh the list of messages after a new message is posted
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default NftViewModal;
