import { useContext } from "react";
import { Link } from "react-router-dom";
import * as ROUTE from "../constants/routes";
import SessionContext from "../session";
import HeaderItems from "./HeaderItems";
import SearchBar from "./SearchBar";

const Header = () => {
  const { session } = useContext(SessionContext);

  return (
    <header className="sticky z-10 top-0 h-16 bg-white border-b border-gray-200 mb-8">
      <div className="container mx-auto max-w-screen-lg h-full p-4">
        <div className="flex justify-between h-full">
          <div className="text-gray-900 text-center flex items-center align-items cursor-pointer">
            <h1 className="flex justify-center w-full pl-5 font-bold tracking-wider">
              <Link to={ROUTE.DASHBOARD}>Pradera</Link>
            </h1>
          </div>
          <div className="text-gray-900 text-center items-center align-items">
            <SearchBar />
          </div>
          {session.authenticated ? (
            <HeaderItems />
          ) : (
            <div className="flex justify-center">
              <Link
                to={ROUTE.LOGIN}
                className="bg-blue-800 hover:bg-blue-700 text-white font-bold p-4 rounded flex justify-center items-center"
              >
                Login
              </Link>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
