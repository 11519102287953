import Image from "./Image";
import Footer from "./Footer";
import Actions from "./Actions";
import { NftWithSocial } from "../../models";
import NftViewModal from "./NftViewModal";
import { useState } from "react";
import classNames from "classnames";
import HiddenButton from "./HiddenButton";

export interface NftContainerProps {
  nftWithSocial: NftWithSocial;
  setNftWithSocial: CallableFunction;
  myNft: boolean;
}

const NftContainer = (props: NftContainerProps) => {
  const nftSocial = props.nftWithSocial;
  const nft = nftSocial.nft;
  const [showNftViewModal, setShowNftViewModal] = useState<boolean>(false);

  return (
    <div className="relative">
      {props.myNft && (
        <div className="absolute bottom-20 right-10 z-10">
          <HiddenButton
            nftWithSocial={props.nftWithSocial}
            setNftWithSocial={props.setNftWithSocial}
          />
        </div>
      )}
      <div
        className={classNames(
          "rounded border bg-white border-gray-200 m-6 max-w-md shadow-sm",
          { "opacity-25": nft.hidden }
        )}
      >
        <button type="button" onClick={() => setShowNftViewModal(true)}>
          <Image
            src={nft.cached_media_url || nft.media_url}
            caption={nft.title}
            description={nft.description}
          />
        </button>
        <Footer title={nft.title} description={nft.description} />
        <Actions
          nft_id={nft.id}
          totalLikes={nftSocial.total_likes}
          liked={nftSocial.observer_likes_it}
          handleFocus={() => setShowNftViewModal(true)}
        />
      </div>
      <NftViewModal
        nftWithSocial={nftSocial}
        show={showNftViewModal}
        setShow={setShowNftViewModal}
      />
    </div>
  );
};

export default NftContainer;
