import { NftCommentData } from "../../models";
import LinkToUser from "../profile/LinkToUser";
import When from "../timeline/When";

const Comment = (props: NftCommentData) => {
  return (
    <div className="bg-white max-w-md">
      <div className="flex flex-row items-center">
        <img
          className="m-1 mr-2 h-12 w-12 rounded-full ring-2 ring-white"
          src=""
          alt=""
        />
        <div>
          <div className="flex flex-row items-center">
            <LinkToUser username={props.from_username} />
            <p className="ml-2 text-sm">{props.comment}</p>
          </div>
          <When
            className="text-xs text-gray-700"
            timestamp={props.created_at}
          />
        </div>
      </div>
    </div>
  );
};

export default Comment;
