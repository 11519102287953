import { useEffect, useState } from "react";
import Header from "../components/Header";
import * as ENDPOINT from "../constants/endpoints";
import { AccountType } from "../components/accounts/Account";
import Accounts from "../components/accounts/Accounts";
import API from "../api";

const Settings = () => {
  const [accounts, setAccounts] = useState<AccountType[]>();

  const onAccountsChange = () => {
    getAccounts();
    importAllNfts();
  };

  const getAccounts = async () => {
    try {
      const response = await API.get(ENDPOINT.ACCOUNT_GET_ALL);
      setAccounts(response.data);
      await API.post(ENDPOINT.NFT_IMPORT_ALL);
    } catch {}
  };

  const importAllNfts = async () => {
    try {
      await API.post(ENDPOINT.NFT_IMPORT_ALL);
    } catch {}
  };

  useEffect(() => {
    getAccounts();
  }, []);

  return (
    <div className="bg-white">
      <Header />
      <div className="container flex mx-auto max-w-screen-md h-screen">
        <div className="mr-10">Settings</div>
        <div className="ml-10">
          <Accounts accounts={accounts} onChange={onAccountsChange} />
        </div>
      </div>
    </div>
  );
};

export default Settings;
