import React, { useEffect, useState } from "react";
import * as ENDPOINT from "../../constants/endpoints";
import API from "../../api";
import {
  EventData,
  NftAddedEventData,
  NftLikeEventData,
  TimelineData,
} from "../../models";
import NftLikeEvent from "./NftLikeEvent";
import NftAddedEvent from "./NftAddedEvent";
import LoadingSpinner from "../LoadingSpinner";

const Timeline = (props: any) => {
  const [timelineData, setTimelineData] = useState<TimelineData | undefined>();

  useEffect(() => {
    const getTimeline = async () => {
      try {
        const response = await API.get(ENDPOINT.TIMELINE);
        setTimelineData({ events: response.data });
      } catch {
        console.log("Error getting timeline");
      }
    };
    getTimeline();
  }, []);

  if (timelineData === undefined) {
    return <LoadingSpinner />;
  }

  if (timelineData.events.length === 0) {
    return <p>Start following some people to see the timeline</p>;
  }

  const renderedTimeline = timelineData.events.map(
    (event: EventData, index: number) => {
      switch (event.event_type) {
        case "nft_like":
          return (
            <NftLikeEvent
              {...(event.content as NftLikeEventData)}
              key={index}
            />
          );
        case "user_follow":
          return <React.Fragment />;
        case "nft_added":
          return (
            <NftAddedEvent
              {...(event.content as NftAddedEventData)}
              key={index}
            />
          );
        default:
          return <React.Fragment />;
      }
    }
  );

  return <div className={props.className}>{renderedTimeline}</div>;
};

export default Timeline;
