import Header from "../components/Header";
import Timeline from "../components/timeline/Timeline";
import Suggestions from "../components/suggestions/Suggestions";

const Dashboard = () => {
  return (
    <div className="bg-gray-100 min-h-screen">
      <Header />
      <div className="grid grid-cols-4 justify-between mx-auto max-w-screen-lg">
        <div></div>
        <Timeline className="col-span-2" />
        <Suggestions />
      </div>
    </div>
  );
};

export default Dashboard;
