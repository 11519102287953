import { Timestamp } from "../../models";
import moment from "moment";

interface WhenProps {
  timestamp: Timestamp;
  className?: string;
}

const When = (props: WhenProps) => {
  const timeFromNow = moment(
    props.timestamp.secs_since_epoch,
    "X",
    true
  ).fromNow();
  return <p className={props.className}>{timeFromNow}</p>;
};

export default When;
