import { useState } from "react";
import LoadingSpinner from "../LoadingSpinner";
import API from "../../api";
import * as ENDPOINT from "../../constants/endpoints";

const ImportNftsButton = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const ImportAllNfts = async () => {
    try {
      setLoading(true);
      await API.post(ENDPOINT.NFT_IMPORT_ALL);
    } catch {
      console.log("Error importing NFTs");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div title="Import all NFTs">
      <button type="button" onClick={ImportAllNfts}>
        {loading ? <LoadingSpinner /> : <ImportIcon />}
      </button>
    </div>
  );
};

const ImportIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
      />
    </svg>
  );
};

export default ImportNftsButton;
