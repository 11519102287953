import { createContext, useContext } from "react";

type ModalContextT = {
  popup?: boolean;
  onClose?: () => void;
};

export const ModalContext = createContext<ModalContextT | undefined>(undefined);

export function useModalContext(): ModalContextT {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error(
      "useModalContext should be used within the ModalContext provider!"
    );
  }

  return context;
}
