import API from "../../api";
import { useEffect, useState } from "react";
import * as ENDPOINT from "../../constants/endpoints";

interface ActionsModalProps {
  nft_id: number;
  totalLikes: number;
  liked: boolean;
  handleFocus: any;
}

const ActionsModal = (props: ActionsModalProps) => {
  const [liked, setLiked] = useState(props.liked);
  const [totalLikes, setTotalLikes] = useState(props.totalLikes);

  useEffect(() => {
    setLiked(props.liked);
    setTotalLikes(props.totalLikes);
  }, [props.liked, props.totalLikes]);

  const handleToggleLiked = async () => {
    try {
      const response = await API.post(ENDPOINT.TOGGLE_LIKE, null, {
        params: { nft_id: props.nft_id },
      });
      const newLike: boolean = response.data.user_likes_nft;
      setLiked(newLike);
      setTotalLikes((totalLikes) =>
        newLike ? totalLikes + 1 : totalLikes - 1
      );
    } catch {
      console.log("Error toggling like of NFT " + props.nft_id);
    }
  };

  return (
    <>
      <div className="flex flex-row justify-right ml-3 text-sm">
        <div className="flex">
          <svg
            onClick={handleToggleLiked}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleToggleLiked();
              }
            }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            tabIndex={0}
            className={`w-6 mr-4 select-none cursor-pointer focus:outline-none ${
              liked ? "fill-red-500 text-red-500" : "text-gray-800"
            }`}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
            />
          </svg>
        </div>
        <div className="mt-3 mb-2 text-gray-700 py-0 text-sm">
          <p className="font-semibold">
            {totalLikes === 1 ? `${totalLikes} like` : `${totalLikes} likes`}
          </p>
        </div>
      </div>
    </>
  );
};

export default ActionsModal;
