import { generatePath, Link } from "react-router-dom";
import { UserInfoSlim } from "../../models";
import * as ROUTE from "../..//constants/routes";
import FollowButton from "../FollowButton";

const Suggestion = (props: UserInfoSlim) => {
  const linkToUser = generatePath(ROUTE.PROFILE, {
    username: props.username,
  });

  return (
    <div className="flex flex-row items-center p-1">
      <div className="basis-16">
        <Link to={linkToUser}>
          <img
            className="h-12 w-12 rounded-full ring-2 ring-white"
            src={props.picture}
            alt=""
          />
        </Link>
      </div>
      <div className="basis-32">
        <Link to={linkToUser}>
          <h1 className="font-semibold">{props.username}</h1>
          <p className="text-sm text-gray-600">{props.fullname}</p>
        </Link>
      </div>
      <FollowButton username={props.username} following={false} />
    </div>
  );
};

export default Suggestion;
