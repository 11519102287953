import { TrashIcon } from "@heroicons/react/outline";
import { MouseEventHandler } from "react";

export interface AccountType {
  address: string;
  chain: string;
}

interface AccountProps {
  account: AccountType;
  onDelete: MouseEventHandler<HTMLButtonElement>;
}

const Account = (props: AccountProps) => {
  return (
    <div className="flex flex-row bg-white p-2 text-sm text-gray-700 hover:bg-gray-50">
      <div className="ml-2 font-bold capitalize">{props.account.chain}</div>
      <div className="ml-4 basis- text-sm tracking-wide text-gray-500">
        {props.account.address}
      </div>
      <button
        onClick={props.onDelete}
        className="ml-8 inline-flex items-center justify-right text-right text-gray-400 hover:text-gray-900"
      >
        <TrashIcon className="mr-2 inline-flex h-4 w-4 items-center" />
      </button>
    </div>
  );
};

export default Account;
