import axios from "axios";
import { StatusCodes } from "http-status-codes";

const baseURL = process.env.REACT_APP_API_ENDPOINT;

const instance = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});

instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    // Logout the user if the session has expired
    if (error.response!.status === StatusCodes.UNAUTHORIZED) {
    }
    return Promise.reject(error);
  }
);

export default instance;
