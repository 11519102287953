import API from "../api";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as ENDPOINT from "../constants/endpoints";
import * as ROUTE from "../constants/routes";
import SessionContext, { Session } from "../session";

interface SignupForm {
  email: string;
  fullname: string;
  username: string;
  password: string;
  signup_code: string;
}

const Signup = () => {
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [fullname, setFullname] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const { session, setSession } = useContext(SessionContext);
  let navigate = useNavigate();

  const isInvalid =
    password === "" || email === "" || fullname === "" || username === "";

  useEffect(() => {
    if (session.authenticated) {
      navigate(ROUTE.DASHBOARD);
    }
  }, [navigate, session]);

  // This will be called when the user submits the login form
  const handleSignup = async (event: any) => {
    event.preventDefault();
    setErrorMsg("");
    try {
      setLoading(true);
      const form: SignupForm = {
        email,
        fullname,
        username,
        password,
        signup_code: code,
      };
      const response = await API.post(ENDPOINT.SIGNUP, form);
      const newSession: Session = {
        authenticated: true,
        user: response.data,
      };
      setSession(newSession);
      navigate(ROUTE.DASHBOARD);
    } catch (error: any) {
      if (error.response) {
        setErrorMsg("Invalid username or password");
      } else {
        setErrorMsg("Unexpected error");
      }
    } finally {
      setLoading(false);
      setPassword("");
    }
  };

  return (
    <div className="bg-gradient-to-t from-neutral-300 to-neutral-50 grid place-items-center h-screen">
      <h1 className="flex text-gray-800 justify-center w-full text-7xl font-bold drop-shadow">
        Pradera
      </h1>
      <div className="flex flex-col bg-neutral-100 border p-4 w-80 rounded shadow-sm">
        {errorMsg && <p className="mb-4 text-xs text-red">{errorMsg}</p>}
        <form onSubmit={handleSignup} method="POST">
          <input
            aria-label="Enter your email address"
            type="text"
            placeholder="Email"
            className="text-sm lowercase text-gray-base w-full mr-3 py-4 px-4 h-2 border rounded border-gray-primary mb-1"
            onChange={({ target }) => setEmail(target.value)}
            value={email}
          ></input>
          <input
            aria-label="Enter your full name"
            type="text"
            placeholder="full name"
            className="text-sm text-gray-base w-full mr-3 py-4 px-4 h-2 border rounded border-gray-primary mb-1"
            onChange={({ target }) => setFullname(target.value)}
            value={fullname}
          ></input>
          <input
            aria-label="Enter your username"
            type="text"
            placeholder="username"
            className="text-sm lowercase text-gray-base w-full mr-3 py-4 px-4 h-2 border rounded border-gray-primary mb-1"
            onChange={({ target }) => setUsername(target.value)}
            value={username}
          ></input>
          <input
            aria-label="Enter your password"
            type="password"
            placeholder="password"
            className="text-sm text-gray-base w-full mr-3 py-4 px-4 h-2 border rounded border-gray-primary mb-1"
            onChange={({ target }) => setPassword(target.value)}
            value={password}
          ></input>
          <input
            aria-label="Enter the signup code"
            type="text"
            placeholder="signup code"
            className="text-sm text-gray-base w-full mr-3 py-4 px-4 h-2 border rounded border-gray-primary mb-1"
            onChange={({ target }) => setCode(target.value)}
            value={code}
          ></input>
          <button
            disabled={isInvalid}
            type="submit"
            className={`bg-blue-900 text-white w-full flex items-center justify-center rounded h-8 font-bold
            ${isInvalid && "opacity-50"}`}
          >
            {!loading ? "Sign up" : Spinner()}
          </button>
        </form>
        <div className="flex justify-center items-center flex-col w-full p-4">
          <p className="text-sm">
            Already have an account?
            <Link to={ROUTE.LOGIN} className="font-bold text-blue-900 p-2">
              Login
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

const Spinner = () => {
  return (
    <svg
      role="status"
      className="mr-2 w-5 h-5 text-gray-200 animate-spin dark:text-gray-900 fill-gray-50"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>
  );
};

export default Signup;
