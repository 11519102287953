import { generatePath, Link } from "react-router-dom";
import * as ROUTE from "../../constants/routes";

interface LinkToUserProps {
  username: string;
}

const LinkToUser = (props: LinkToUserProps) => {
  return (
    <Link
      className="font-bold text-gray-800"
      to={generatePath(ROUTE.PROFILE, {
        username: props.username,
      })}
    >
      {props.username}
    </Link>
  );
};

export default LinkToUser;
